exports.components = {
  "component---src-pages-amianto-js": () => import("./../../../src/pages/amianto.js" /* webpackChunkName: "component---src-pages-amianto-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-grupo-js": () => import("./../../../src/pages/grupo.js" /* webpackChunkName: "component---src-pages-grupo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legislacao-js": () => import("./../../../src/pages/legislacao.js" /* webpackChunkName: "component---src-pages-legislacao-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-templates-o-nosso-menu-js": () => import("./../../../src/templates/o-nosso-menu.js" /* webpackChunkName: "component---src-templates-o-nosso-menu-js" */)
}

